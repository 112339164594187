import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ApolloError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
// eslint-disable-next-line no-restricted-imports
import { MissingPermissionException } from '../../models/utils/error';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Logger } from 'src/app/services/logger/logger.service';
import { IdentityService } from 'src/app/services/identity/identity.service';

const logger = new Logger('ErrorHandler');

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService implements ErrorHandler {
  constructor(
    private t: TranslateService,
    private toast: ToastService,
    private oidcSecurityService: OidcSecurityService,
    private identity: IdentityService
  ) {}

  handleError(error: HttpErrorResponse | ApolloError): Observable<never> {
    if (error instanceof ApolloError) {
      if (
        error.networkError &&
        (error.networkError as HttpErrorResponse).error?.errors
      ) {
        this.handleGraphQlErrors(
          (error.networkError as HttpErrorResponse).error.errors
        );
      }
      this.handleHttpError(error.networkError as HttpErrorResponse);
    } else {
      this.handleHttpError(error);
    }

    return throwError(() => error);
  }

  private handleHttpError(e: HttpErrorResponse) {
    if (e.status === 401) {
      this.toast.error(
        this.t.instant('common.error'),
        this.t.instant('error.unauthorized')
      );
      if (this.identity.identityUser$.value)
        setTimeout(() => {
          this.oidcSecurityService.logoff().subscribe(() => {
            logger.warn('Signing off due to 401 error');
          });
        }, 2500);
      return;
    }

    if (e.status === 403) {
      if (e.error && (e.error as MissingPermissionException).permissions) {
        const missingPermissions = (e.error as MissingPermissionException)
          .permissions;
        this.toast.error(
          this.t.instant('common.error'),
          this.t.instant('error.missing_permissions', {
            permissions: missingPermissions.join(', '),
          })
        );
        return;
      }
      this.toast.error(
        this.t.instant('common.error'),
        this.t.instant('error.permission_denied')
      );
      return;
    }

    this.toast.error(this.t.instant('common.error'), e.message);
    console.error(e);
  }

  private handleGraphQlErrors(errors: GraphQLError[]) {
    errors.forEach((e: GraphQLError) => {
      this.toast.error(
        this.t.instant('common.api_error'),
        `${e.message}${e.path ? ' ' + e.path.join('.') : ''}`
      );
    });
  }
}
